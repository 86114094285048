p{
    color: black;
}
.text-header{
        color: #87b84b;
        font-weight: 300;
        font-size: 38px;
        line-height: 44px;
        margin-top: 20px;
}
.card-background{
border:0px;
padding:10px;
}

.horizontal-rule {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
}

element.style {
}
.OAuth-code {
    margin-bottom: 48px;
    padding: 14px 20px 14px 30px;
    border-left: 5px solid #87b84b;
    font-family: Inconsolata,monospace;
    color: black;
    font-size: 14px;
    line-height: 22px;
}

.accesstoken-heading {
    margin-bottom:16px;
    margin-top: 24px;
    font-size: 16px;
    font-weight: 800;
}

.accesstoken-code{
    background: #efefef;
    border: 1px solid #ccc;
    padding: 4px;
    border-radius: 2px;
    font-size: 13px;
}
