.text-Sub-header {
    color: #8798ad;
    font-size: 12px;
    line-height: 16px;
}

.text-header{
        color: #87b84b;
        font-weight: 300;
        font-size: 38px;
        line-height: 44px;
        margin-top: 20px;
}

.registration-labels {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #9493a0;
}

.registration-submit {
    height: 45px;
    border-radius: 5px;
    background-color: #9f2160;
    font-family: FS-Me,sans-serif;
    font-weight: 700;
    text-decoration: none;
    color: #fff;
    padding-left: 30px;
    padding-right: 30px;
    min-width: 0;
    flex-wrap: nowrap;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
 }

 .registration-list{
 font-size:1rem;
 }
 table.custom_table{
   margin-left: auto;
   margin-right: auto;
   width: 60%;
 }
 .text{
    text-align: center;
    padding-bottom: 10px;
    background-color:lightgray ;
 }