  /* For pdf */
  .react-pdf__Page:first-child {
    margin-top: 10px !important;
  }
  .react-pdf__Page:last-child {
    margin-bottom: 30px;
  }

  .react-pdf__Page__annotations.annotationLayer {
    padding: 10px;
  }
  .react-pdf__Document{
    display: flex;
    -webkit-display: flex;
    justify-content: center;
    -webkit-justify-content: center;
    flex-flow: column;
    -webkit-flex-flow: column;
    align-content: center;
    -webkit-align-content: center;
    align-items: center;
    -webkit-align-items: center;
  }
  .react-pdf__Page__canvas {
    // margin: 0 auto;
    box-shadow: 5px 5px 5px 1px #ccc;
    border-radius: 5px;
  }
  .annotationLayer .linkAnnotation > a:hover, .annotationLayer .buttonWidgetAnnotation.pushButton > a:hover {
    background: none !important;
    box-shadow: none !important;
  }
  @media only screen and (max-width: 480px) {
    .show-mobile {
      display: block;
      /* .annotationLayer .linkAnnotation > a, .annotationLayer .buttonWidgetAnnotation.pushButton > a {
        left: 1.5rem;
        } */
    }
    .show-tab {
      display: none;
    }
    .show-desktop {
      display: none;
    }
  }

@media only screen and (min-width: 481px) and (max-width: 991px) {
    .show-mobile {
      display: none;
    }
    .show-tab {
        display: block;
        /* .annotationLayer .linkAnnotation > a, .annotationLayer .buttonWidgetAnnotation.pushButton > a {
            left: 0.7rem;
        } */
    }
    .show-desktop {
      display: none;
    }
  }
  @media only screen and (min-width: 992px) {
    .show-mobile {
      display: none;
    }
    .show-tab {
        display: none;
    }
    .show-desktop {
      display: block;
     /*  .annotationLayer .linkAnnotation > a, .annotationLayer .buttonWidgetAnnotation.pushButton > a {
          left: 20%;
      } */
    }
  }
  