.loader,
.loader:after {
  border-radius: 50%;
  width: 6em;
  height: 6em;
}
.loading-spinner {
  background-color: rgba(0, 0, 0, 0.2);
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 1200;
  top: 0;
  right: 0;
}

.loading-spinner-redirect {
  background-color: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 500px;
  z-index: 1200;
  margin-top: -2%;
}

.loader {
  font-size: 10px;
  position: absolute;
  z-index: 999;
  overflow: hidden !important;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-top: 1.1em solid rgba(0, 0, 0, 0.2);
  border-right: 1.1em solid rgba(0, 0, 0, 0.2);
  border-bottom: 1.1em solid rgba(0, 0, 0, 0.2);
  border-left: 1.1em solid #000000;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
