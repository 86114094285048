@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&amp;display=swap');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700,800&amp;display=swap');
@import url('https://pro.fontawesome.com/releases/v5.10.0/css/all.css');


@font-face{
  font-family:'FontAwesome';
  src:url('https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/fonts/fontawesome-webfont.eot?v=4.7.0');
  src:url('https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0./fonts/fontawesome-webfont.eot?#iefix&v=4.7.0') format('embedded-opentype'),
  url('https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0./fonts/fontawesome-webfont.woff2?v=4.7.0') format('woff2'),
  url('https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/fonts/fontawesome-webfont.woff?v=4.7.0') format('woff'),
  url('https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/fonts/fontawesome-webfont.ttf?v=4.7.0') format('truetype'),
  url('https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular') format('svg');font-weight:normal;font-style:normal;
}

// @import './Variables.scss';
$PrimaryColor: #274463;
$ErrorColor: #a81f00;
// $SuccessColor: #02837B;
$SuccessColor: #005A64;
$BackgroundColor: #F4F4F4;
$fontFamily: 'Open Sans', sans-serif !important;
$fontSize: 1rem;
$mar-pad-32: 32px;
$mar-pad-24: 24px;
$border: 1px solid #AAAFB9;
// $SuccessColor: #02837B;
$SuccessColor: #005A64;
$fontFamily: 'Open Sans', sans-serif !important;
$disabledColor: #888888;
$RejectColor: #cc0000;

/*** Styling Primary content start ***/
 * {
  font-family: 'Open Sans', sans-serif, Arial;
}
html,
body {
  margin: 0px;
  padding: 0px;
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
  letter-spacing: 0.025em;
}
div,
main,
section,
article {
  max-width: 100%;
}

  #root {
    width: 100%;
    min-height: 100%;
    overflow-x: hidden;
  }
  
  body{
    height: 100vh;
    background-color: $BackgroundColor !important;
    font-size: $fontSize;
    font-weight: 400;
    ::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }
    ::-webkit-scrollbar-thumb{
      width: 5px;
      height: 7px;
      background: #c1b6b6;
      outline: 1px solid slategrey;
    }
  }
  #root
  {
    height: 100% !important;
  }
  .body-container {
    background-color: $BackgroundColor !important;
  }
  .tabs-container
  {
    width: 100%;
    padding: 0.5rem !important;
    padding: 20px 23px !important;
    background-color: $BackgroundColor;
  }
  p {
    font-size: $fontSize;
    font-family: $fontFamily;
  }
  section p {
    padding: 0 !important;
  }
  section ul li {
    list-style: none;
    text-align: center;
  }

  a {
    color: #0047BA !important;
  }
  a:focus, a:hover {
    outline: none;
  }
  h5 {
    font-weight: 600 !important;
    .text-left {
      font-size: 1.2rem !important;
      background-color: $PrimaryColor !important;
      opacity: 0.1;
    }
  }
  h3 {
    font-size: 1.74rem;
}
  button:focus {
    outline: none !important;
  }
  .col-sm-4 {
    margin: 0;
  }

  input::-ms-reveal, input::-ms-clear {
    display: none;
  }

/*--Mixins Starts Here--*/
@mixin border-radius($val) {
  border-radius: $val;
  -webkit-border-radius: $val;
  -ms-border-radius: $val;
}
@mixin boxShadow($val) {
  box-shadow: $val;
  -webkit-box-shadow: $val;
  -ms-box-shadow: $val;
}
/*--|--*/
  .App-logo {
    height: 40vmin;
  }
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  .App-link {
    color: #09d3ac;
  }

  .MuiInputLabel-root
  {
    color: #000000 !important;
  }
  /* Styling Primary content end */

  /*** Styling Form Elements start ***/
  .form-wrapper
  {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  // .mui-custom-form 
  // {
  //   &.with-link
  //   {
  //     .MuiInput-input
  //     {
  //       color: $PrimaryColor !important;
  //     }
  //   }
  //   .MuiFormLabel-root
  //   {
  //     font-size: 20px !important;
  //     white-space: nowrap;
  //     color: #000 !important;
  //   }
  //   .MuiInput-input
  //   {
  //     color: #000;
  //     font-size: 19px;
  //     width: 100%;
  //   }
  //   .input-text
  //   {
  //     color: #000;
  //     font-size: 16px;
  //   }
  //   .Mui-error,.Mui-required, .text-danger
  //   {
  //     color: $ErrorColor !important;
  //   }
  //   .MuiSelect-select 
  //   {
  //     margin-top: 3px;
  //   }
  //   .MuiTextField-root,.MuiInput-input
  //   {
  //     width: 100% !important;
  //   }
  // }

  // dropdown menu styling
  .MuiList-root {
    max-height: 255px !important;
    // min-width: 15rem !important;
    // width: 23rem !important;
  }
  .MuiMenuItem-root {
    min-height: auto !important;
    white-space: normal !important;
  }
  .MuiPaper-root.MuiMenu-paper {
    margin-left: -1rem !important;
  }
  .mui-custom-form 
  {
    margin: 12px 2.5%;
    width: 100%;
    .MuiInput-root
    {
      height: 40px;
      margin-top: 10px;
      border: solid 1px #707070;
      border-radius: 5px;
      padding: 2px 12px;
      &:focus-within{
        border-color: #0147BA;
        //border-width: 1px;
      }
      &::before, &::after{
        border-width: 0px !important;
      }
    }
    .MuiInput-root.Mui-error {
      border: 1px solid #a81f00 !important;
    }
    .MuiFormLabel-asterisk
    {
      color: $ErrorColor !important;
    }
    .MuiFormLabel-root
    {
      font-size: 1.25rem !important;  /* 1.063em !important */
      white-space: nowrap;
      color: #000 !important;
      letter-spacing: 0;
      font-family: $fontFamily;
    }
    &.displayFilterFont{
      font-size: 0.8em !important;
    }
    .Mui-disabled
    { 
      border-radius: 5px;
      border:0px !important;
      &.MuiInputBase-root
      {
        background-color: #d8d8d8 !important;
        border: 1px solid #b0b0b0 !important;
      }
      .MuiInput-input
      {
        color: #000000 !important;
      }
      &::before{
        border-bottom-width: 0px;
      }
    }
    .MuiFormLabel-root.small-label
    {
      font-size: 14px !important;
      margin-bottom: 12px;
      span {
        color: $ErrorColor;
      }
    }
    .MuiFormLabel-root.fiscal-label
    {
      font-size: 12.5px !important;
      margin-bottom: 12px;
      span {
        color: $ErrorColor;
      }
    }
    .MuiFormLabel-root.very-small-label
    {
      font-size: 13px !important;
      margin-bottom: 12px;
      span {
        color: $ErrorColor;
      }
    }
    legend
    {
      width: 60px;
    }
    input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
          /* display: none; <- Crashes Chrome on hover */
          -webkit-appearance: none;
          margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
      }

      input[type=number] {
          -moz-appearance:textfield; /* Firefox */
      }
    .MuiInput-input
    {
      color: #000;
      font-size: 14px !important;
      width: 100%;
    }
    
    .MuiSelect-select 
    {
      margin-top: 3px;
    }
    .MuiTextField-root,.MuiInput-input
    {
      width: 100% !important;
    }
    &.has-link
    {
      .Mui-disabled
      { 
        .MuiInput-input
        {
          color: #0047BA !important; 
          cursor: pointer !important;
        }
        &::before{
          border-bottom-width: 0px;
        }
      }
    }
  }
  
  /*** Styling Form Elements end ***/
  
  /*** Mui classes override start ***/
  .MuiFormHelperText-root.Mui-error.Mui-required, .iox-required
  {
    color: $ErrorColor !important;
    padding: 0 !important;
    font-size: 0.75rem !important;
  }
  .MuiTab-root 
  {
    outline:none !important;
  }
  .MuiTouchRipple-root,.Mui-selected
  {
    border-bottom-color: transparent !important;
  }
  .MuiListItem-gutters.MuiListItem-button a:hover{
    color: inherit !important;
    text-decoration: none !important;
  }
  .PrivateTabIndicator-colorSecondary-63
  {
    background-color: transparent !important;
  }
  .MuiTabs-indicator
  {
    background-color: transparent !important;
  }
  .MuiPickersDay-daySelected
  {
    background-color: $PrimaryColor !important;
    &:hover{
      background-color: #3f51b5 !important;
    }
  }
  .MuiBox-root-254
  {
    border-width: 0px !important;
  }
  .MuiCheckbox-colorPrimary
  {
    .MuiSvgIcon-root
    {
      color: $PrimaryColor !important;
    }
  }
  .MuiInputLabel-formControl {
    position: inherit !important;
  }
  /*** Mui classes override end ***/
  
  /*** Error and success msg start ***/
  .alert-danger.custom-alert
  {
    background-color: lighten( $ErrorColor, 55% ) !important;
    border-color: lighten( $ErrorColor, 50% ) !important;
    padding: 1.1% 2rem !important;
    li 
    {
      color: $ErrorColor;
      font-size: 0.875rem !important;
    }
  }
  .alert-success.custom-alert{
    background-color: darken( #d4edda, 5% ) !important;
    border-color: darken( #c3e6cb, 10% ) !important;
    padding: 1.1% 2rem !important;
    li 
    {
      color: darken( $SuccessColor, 15% ) !important;
      font-size: 0.875rem !important;
    }
  }
  .danger-text
  {
    color: $ErrorColor !important;
    font-size: 13px !important;
  }
  /*** Error and success msg end ***/

/* CSS CLASSES FOR BUTTONS START */
  .btn-primary
  {
    color: white  !important;
    font-weight: 600 !important;
    font-size: 0.8rem !important; /* 1em */
    text-transform: uppercase;
    margin: 0.5rem auto;
    padding: 0;
    background-color: $PrimaryColor !important;
    border: 1px solid $PrimaryColor;
    //height: 2.75rem;
    //width: 12.5rem;
    border-radius: 0.25rem;
    text-align: center !important;
  }
  .btn-transparent
  {
    color: $PrimaryColor !important;
    font-weight: 600!important;
    font-size: 0.8rem!important; /* 1em */
    margin: 0.5rem 0 !important;
    padding: 0;
    text-transform: uppercase;
    border: 1px solid $PrimaryColor !important;
    height: 2.75rem;
    width: 12.5rem;
    border-radius: 0.25rem;
    background-color: transparent !important;
    text-align: center !important;
 }

  /* CSS CLASSES FOR BUTTONS END */

  /************* CSS FOR PAGES START *************/
  /*** Login page starts here ***/
  #terms-condition-checkbox {
    margin: 1.25rem 0.5rem 0.75rem!important;
  }
  .MuiCheckbox-root {  // for password page checkbox
    padding: 3px !important;
    margin-bottom: auto !important;
  }
  .contact-us-block {
    margin: 0.5rem 0;
    padding: 0 6% 0 1.5%;
    text-align: right;
    // font-weight: 600;
    .alert-danger.custom-alert li {
      text-align: left !important;
    }
  }

  .login-header {
    font-size: 1rem !important;
    color: #000 !important;
    font-weight: 600;
    padding: 1.5rem;
  }
  // .displayInline {
  //   display: contents;
  // }
  .admin-logo {
    border: 3px solid #0047BA;
    border-radius: 50%;
    padding: 3.5px;
    // margin-bottom: 0.28rem !important;
  }
  .member-icon {
    width: 55px !important;
    height: 55px !important;
    fill: #0047BA !important;
  }
  .blue-font {
    color: #0047BA !important;
  }
  .font-weight-600 {
    font-weight: 600;
  }
  .show-on-xs {
    display: none;
  }
  .login-tab {
    //min-height: 250px;
    width: 100%;
    background-color: #fff;
    box-shadow: 1px 1px 8px 0px #ccc;
    -webkit-box-shadow: 1px 1px 8px 0px #ccc;
    -moz-box-shadow: 1px 1px 8px 0px #ccc;
    overflow: hidden;
  }
  .login-form-container {
      position: relative;
      min-height: 13rem;
      height: 75%;
  }
  .login-tab-error {
    width: 89.4%;
    margin: 0 auto 0 3.3rem;
  }
  .login-footer-container {
    position: sticky;
    bottom: 0;
    border-top: 1px solid lightgray;
    width: 96%;
    margin: 0 auto;
  }
  .admin-footer-container {
    position: absolute;
    bottom: 0;
    border-top: 1px solid lightgray;
    width: 94% !important;
    margin: 0 auto;
  }
  .carousel-control-next-icon, .carousel-control-prev-icon {
    display: none !important;
    width: 0 !important;
  }
  .carousel-indicators {
    margin: 0 4% 31% !important;
    justify-content: left !important;
  }
  .carousel-indicators > li {
    border-radius: 50%;
    height: 10px !important;
    width: 10px !important;
  }
  .carousel-caption {
    padding-bottom: 0 !important;
    left: 4% !important;
    right: auto !important;
    background-color: rgba(0,0,0,0.01) !important; // to remove contrast error
    color: #fff !important;
  }
  .main-container {
    width: 100%;
    min-height: calc(100% - 65px);
    overflow-x: hidden;
    display: flex;
    //flex-direction: column;
    padding-bottom: 1rem;
  }
  .help-container {
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .header-container {
    width: 100%;
    padding: 0 1rem;
    margin: 0;
    background-color: white;
  }
  .footer-container {
    height: 3.5rem;
    width: 100%;
    // background-color: #E8E9E9;
    // border-top: 1px solid lightgray;
    text-align: right;
  }
  .btn-primary-login-sm {
    margin: 0.5rem 0;
    width: 10rem;
  }
  .btn-primary-pw {
    margin: 0.75rem auto;
    width: 9rem;
  }
  // login-password page
  .PrivateSwitchBase-root-1 {
    padding: 5px 5px 5px 0 !important;
    margin-bottom: auto !important;
  }
  @media only screen and (min-width: 1061px) {
    #g-recaptcha {
      transform:scale(1.44) !important;
      transform-origin:0 0 !important;
      margin: 0 0 1.5rem 1rem !important;
    }
  }
  @media only screen and (min-width: 991px) and (max-width: 1060px) {
    #g-recaptcha {
      transform: scale(1.25) !important;
      transform-origin: 0 0 !important;
      margin: 0 0 0 1rem !important;
    }
  }
  @media only screen and (min-width: 576px) and (max-width: 991px) {
    #g-recaptcha {
        transform: scale(1.4) !important;
        transform-origin: 0 0 !important;
        margin: 0 0 1.5rem 1rem !important;
    }
  }
  @media only screen and (min-width: 370px) and (max-width: 410px) {
    #g-recaptcha {
        transform: scale(0.88) !important;
        transform-origin: 0 0 !important;
        margin: 0 0 0 1rem !important;
    }
    #terms-condition-checkbox {
      margin: 0 0.5rem 0.5rem !important;
    }
  }
  @media only screen and (min-width: 411px) and (max-width: 575px) {
    #g-recaptcha {
        transform: scale(1) !important;
        transform-origin: 0 0 !important;
        margin: 0 0 0 1rem !important;
    }
    #terms-condition-checkbox {
      margin: 0 0.5rem 0.5rem !important;
    }
  }
  #g-recaptcha {
    transform:scale(0.84);
    transform-origin:0 0;
    margin: 0 0 0 1rem;
  }  
  // css for iPhone 5
  @media only screen and (max-width:350px) {
    .custom-list-style {
      list-style-type: none;
      padding-inline-start: 1.25rem;
    }
    #terms-condition-checkbox {
      margin: 0 0.5rem 0.5rem !important;
    }
    #g-recaptcha {
      transform:scale(0.7) !important;
      transform-origin:0 0;
      margin: 0.25rem 1rem;
    }
    .feedback-data-display-container {
      padding: 0.75rem;
    }
    .feedback-data-container {
      padding: 1rem !important;
    }
    h2 {
      font-size: 1.7rem !important;
    }
  }
  @media only screen and (max-width:1024px) {
    .chart-container {
      overflow-x: scroll
    }
  }
  @media only screen and (max-width:991px) {
    .admin-footer-container {
      position: relative;
      width: 95%;
    }
    // login page media-xs
    .contact-us-block {
      padding: 0 2% 0 1.5%;
    }
    .vertical-wrapper {
      flex-direction: column;
    }
    .login-tab {
      width: 98%;
      margin: 0 1% 1.5em;
    }
    .login-tab-error {
      width: 98%;
      margin: 0 auto;
  }
    .show-on-md {
      display: none;
    }
    .show-on-xs {
      display: inline;
    }
    .btn-primary-login-sm {
      font-size: 1rem;
      margin-left: 0;
      width: 7rem;
    }
    //login-password page xs 
    .PrivateSwitchBase-root-1 {
      margin-bottom: auto !important;
    }
    .rc-anchor-light {
      background: $BackgroundColor;
      color: #000;
    }
  }
  /*** Login page ends here *****/

  /*** Custom scroll menu start ***/
  .menu-wrapper--inner {
    position: relative;
    left: -2rem;
    // transition: none !important;
  }
  .horizontal-menu {
    background-color: #fff;
  }
  .menu-item {
    color: #595959;
    padding: 0 1rem;
    margin: 0.25rem 1.15rem;
    cursor: pointer;
    border: none;
    text-transform: uppercase;
  }
  .menu-item-wrapper.active, .menu-item-wrapper:focus{
    outline: none;
    .menu-item {
      color: $PrimaryColor;
    }
  }
  .menu-item.active {
    border-bottom: 3px solid $PrimaryColor !important;
    font-weight: 600;
  }
  
  .scroll-menu-arrow {
    padding: 0.75rem;
    cursor: pointer;
    color: #AAAFB9;
  }
  /*** Custom scroll menu start ***/

  /*** Claims Details Page css ***/

  //claims pdf
  .pdf-container {
    border: 1px solid;
    .pdf-header {
      padding: 1rem;
    }
    .pdf-section {
      .pdf-section-head {
        background-color: $PrimaryColor;
        color: #fff;
      }
    }
  }
  // radio button start
  .MuiSvgIcon-root {
    font-size: 1.1rem !important;
  }
  .MuiSelect-root {
    background-color: #fff !important;
  }
  .MuiSelect-icon {
    top: calc(50% - 9px) !important;
    right: 8px !important;
  }
  .MuiRadio-colorSecondary.Mui-checked {
    color: $PrimaryColor !important;
  }
  .PrivateSwitchBase-root-3 {
    padding: 0 0 0.25rem 0 !important;
  }
  // radio button end
  .other-textbox {
    margin: 0 1rem 0 0.5rem !important;
    padding: 0 !important;
  }
  td.toggle-arrow {
    vertical-align: middle !important;
  }
  table.MuiTable-root.table.border-0 {
    background-color: $BackgroundColor;
  }
  .claims-alert {
    text-align: center !important;
    top:50%;
    left: 0;
    right: 0;
    position: fixed;
    color: #595959;
  }
  .gray-font {
    font-size: .73rem;
    color: #595959;
  }
  .alert-dialog {
    .MuiDialog-paper {
      padding: 2rem !important;
      margin: 5% !important;
      border-radius: 8px;
    }
  }
  .show-on-mob {
    display: none !important;
  }
  @media only screen and (max-width: 540px) {
    .inner-table tr.MuiTableRow-root {
      display: inline-grid;
      border-block-end: 1px solid gray;
      width: -webkit-fill-available;
      .MuiTableCell-root {
        border: none;
      }
    }
    .MuiBox-root-2 {
      margin: 1px !important;
      padding: 8px !important;
    }
    .hide-on-mob {
      display: none !important;
    }
    .show-on-mob {
      display: inline !important;
    }
    td.MuiTableCell-root.MuiTableCell-body {
      padding: 0.5rem;
    }
    .toggle-arrow {
      padding: 0 !important;
    }
    .other-textbox {
      margin: 0 1rem 0 0.25rem !important;
      padding: 0 !important;
    }
    .btn-alert {
      width: 45% !important;
    }
    .alert-dialog {
      .MuiDialog-paper {
        padding: 1rem !important;
      }
    }
  }
  /*** Claims Details Page css ***/
  /* Registration page starts here*/
  .reg-footer {
    justify-content: flex-end;
  }
  .form-check-input {
    margin-block-end: auto !important;
  }
  .red-font {
    color: $ErrorColor !important;
  }
  .checkbox-error .MuiIconButton-label {
    border: 1px solid $ErrorColor !important;
  }
  svg.MuiSvgIcon-root.red-font, svg.MuiSvgIcon-root.alert-font {
    margin-top: 1.2rem;
    margin-left: 1rem;
  }
  .custom-date-picker{
    svg.MuiSvgIcon-root {
    width: 1.5rem !important;
    height: 1.5rem !important;
    }
  }
  .form-header-container {
    display: flex;
    align-items: center;
    padding: 0.75rem;
    height: 3.5rem;
    margin-bottom: 0px;
    background-color: $PrimaryColor;
  }
  .registration-container {
    min-height: 500px;
    width: 100%;
    margin: 0;
    background-color: $BackgroundColor;
  }
  .success-container {
    width: 60%;
    padding: 5%;
    min-height: 200px;
    margin: 2% auto;
    background-color: #fff;
  }
  .success-alert-container {
    width: 60%;
    margin: 2% auto;
  }
  .contact-us-block-success {
    margin: 1% 20% 1% 0;
    text-align: right;
  }

  hr {
    margin-bottom: 0.1rem !important;
    margin-top: 0.1rem !important;
  }
  fieldset {
    background-color: #fff;
  }
  .custom-fieldset {
    height: 100%;
  }
  .sub-header {
    display: inline-block;
    width: auto;
    font-size: 1.1rem !important;
    color: #000 !important;
    padding: 1rem;
    font-weight: 600 !important;
  }
  .form-heading {
    color: #fff;
    font-size: 1.35rem; /* 1.750 em */
    letter-spacing: 0.1px;
    float: left !important;
    vertical-align: middle !important;
  }
  .reg-heading {
    color: #fff;
    font-size: 1.35rem; /* 1.750 em */
    letter-spacing: 0.1px;
    float: left !important;
    vertical-align: middle !important;
  }
  .secondary-form-heading {
    color: #fff;
    float: right !important;
    vertical-align: middle !important;
    margin-left: auto;
  }
  .name-text {
    font-size: 1rem !important;
  }  
  #date-picker-inline {
    width: 375% !important;
  }
  //  registration dialog starts
  .custom-dialog.dialog-520
  {
    .MuiDialog-paperWidthSm
    {
      overflow-y: auto;
      max-width: 550px;
      max-height: 550px;
      border-radius: 8px;
      padding: 2%;
      -ms-overflow-style: none;
    }
    .MuiDialog-paperWidthSm::-webkit-scrollbar {
      display: none;
    }
  }
  .MuiDialogContent-root {
    overflow-y: hidden !important;
    min-height: 7rem;
  }
  .button-wrapper
  {
    display: flex;
    width: 98%;
    margin: 0 auto;
  }
  .btn-dialog {
    padding: 0 0.5rem !important;
    margin: 0.75rem !important;
    width: 95%;
  }
  // terms of use page list flex
  .flex-list {
    flex-direction: row !important;
  }

  // registration dialog ends
  .dynamic-padding-dup {
    padding-top: 2.6rem; //for large devices
  }
  .dynamic-padding {
    padding-top: 1.25rem;
  }
  .attest-error {
    color: $ErrorColor !important;
    padding: 0 !important;
    font-size: 0.75rem;
  }
  @media only screen and (min-width: 768px) and (max-width: 903px) {
    .dynamic-padding-dup {
      padding-top: 3.7rem; //ipad and up
    }
    .dynamic-padding {
      padding-top: 2.35rem;
    }
  }
  @media only screen and (max-width: 1024px) {
    .header-container {
      padding: 0;
    }
  }
  @media only screen and (min-width: 768px) {
    // login page
    .carousel-caption {
      //bottom: 18% !important;
      color: #fff !important;
    }
    .dos {
      width: 21% !important;
    }
    .font-md {
      font-size: 1.15rem !important;
    }
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .MuiDialogTitle-root.Component-root-1 {
      overflow-y: hidden !important;
    }
    .dialog-520.MuiDialog-paperWidthSm {
      height: 98% !important;
    }
    .custom-dialog.dialog-520 {
      .MuiDialog-paperWidthSm {
        height: 98% !important;
      }
    }
    .dynamic-padding {
      padding-top: 1.4rem;
    }
    .dynamic-padding-dup {
      padding-top: 2.6rem;
    }
    .success-container, .success-alert-container {
      width: 98%;
      margin: 2% auto;
    }
    .contact-us-block-success {
      margin: 1% 0 1% 0;
      text-align: right;
    }
    .form-heading {
      font-size: 1.25rem; /* 1.750 em */
    }
    .reg-heading {
      font-size: 1.25rem; /* 1.750 em */
    }
    .name-text {
      font-size: 0.8rem !important;
    }
    
  }
  @media only screen and (max-width: 575px) and (orientation: portrait) {
    .carousel-indicators {
      margin: 0 6% 35% !important;
      justify-content: left !important;
    }
    .MuiPaper-root.MuiMenu-paper {
      margin-left: 0 !important;
      max-width: calc(100% - 80px);
    }
    .admin-footer-container {
      position: relative;
      width: 91%;
    }
    .heading-wrap {
      width: 33% !important;
      padding-top: 0 !important;
      font-size: 0.75rem !important;
    }
    .cp-heading {
      font-size: 0.75rem !important;
    }
    .form-header-container {
      padding: 0.25rem !important;
      height: 3.25rem;
    }
    /*chan.carousel-caption {
      bottom: 0 !important;
      padding-bottom: 0 !important;
    }*/
  }
  @media only screen and (max-width: 575px) {
    .small-size-font {
      font-size: 0.75rem !important;
    }
    .form-heading {
      font-size: 1rem; /* 1.750 em */
    }
    .reg-heading {
      font-size: 1.1rem;
    }
    .secondary-form-heading {
      font-size: 0.9rem;
    }
    .form-header-container {
      padding: 0.5rem;
    }
    .name-text {
      font-size: 0.65rem !important;
    }
    .dynamic-padding {
      padding-top: 0;
    }
    .dynamic-padding-dup {
      padding-top: 0;
    }
    .button-wrapper
    {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
    }
    .btn-xs
    {
      margin: 0.5rem auto !important;
      width: 100% !important;
    }
    .btn-dialog
    {
      padding: 0 !important;
      margin: 0 1rem 1rem !important;
    }  
    .success-container, .success-alert-container {
      width: 98%;
      margin: 6% auto;
    }  
    .contact-us-block-success {
      margin: 1% 0 1% 0;
    }
    // terms of use page list flex
    .flex-list {
      flex-direction: column !important;
    }
  }
/* Registration page ends here*/

/* Forgot Password starts here*/
.dynamic-btn-margin {
  margin-bottom: 1.35rem;
}
.password-recovery-container {
  width: 96%;
  padding: 1rem !important;
  margin: 0 auto;
}
.feedback-main-container{
  width: 100%;
  padding: 0.5rem !important;
  padding: 20px 23px !important;
  background-color: $BackgroundColor;
}
.feedback-container{
  background-color: white;
  margin-top: 1.5rem !important;  
  border: 1px solid #DBDBDB;
}
.feedback-alert {
  position: absolute;
  text-align: center !important;
  top: 30%;
  left: 0;
  right: 0;
  color: #595959;
}
.feedback-dropdown{
  .MuiInput-root {
     //width: 10rem !important;
     width: fit-content;
  }
}
.border-line-color {
  border-top: 1px solid #DBDBDB;
  min-height: 20rem;
}
.container-background {
  background-color: $BackgroundColor;
  margin: 0;
  height: 600px;
  width: 1010px;
} 
.forgot-username-container
{
  max-width: 980px;
  margin: 0.5rem auto !important;
}
.contactUs-container
{
  width: 100% !important;
  margin: 0.5rem auto !important;
  background-color: $BackgroundColor;
}
.contactUs-subContainer {
  background-color: white;
  width: 100% !important;
}
.dark-blue-color {
  color: $PrimaryColor;
}
.contact-hyperlink {
  word-wrap: break-word;
}
.semi-bold, .radio-notes strong {
  font-weight: 600;
}
.contactUs-headers-padding {
  padding: 2rem !important;
}
.terms-of-use-head {
  background-color: $PrimaryColor;
  color: #fff;
}
.border-bottom-contactUs {
  border-bottom: 1px solid #AAAFB9;
}
.contactUs-questions-padding {
  padding: 1.5rem 2rem 0 !important;
}
.contactUs-Question-color {
  color: #555B5A;
}
.contactUs-subHeader-margin {
  margin-top: 1.5rem !important;
}
.contactUs-table-headers {
  border: 1px solid #AAAFB9;
  padding: 1.5rem !important;
  background-color: #f3f4f3;
}
.contactUs-table-data {
  border: 1px solid #AAAFB9;
  padding: 1.5rem !important;
}

.mytable{
	width:100%;
	margin:0 !important;
	border-collapse:collapse;
}
.mytable td{
	border: 1px solid #AAAFB9;
	vertical-align:top;
}

@media screen and (max-width:767px) {
	table.mobile-optimised {
		word-wrap:break-word;
  }
  table.mobile-optimised .contactUs-table-data {
    padding: 0 !important;
  }
	table.mobile-optimised thead {
		display:none
	}
	table.mobile-optimised td {
		display:block;
		float:left;/* ie9 and under hack */
		width:100%;
		clear:both;
	}
	table.mobile-optimised tbody, table.mobile-optimised tr {
		display:block
  }
  
	.mobile-optimised td:before {
		content:attr(data-th);
		display:block;
    font-weight:600;
    font-size: $fontSize;
    border: 1px solid #AAAFB9;
    padding: 1.5rem !important;
    background-color: #f3f4f3;
	}
	.mobile-optimised tbody tr {
    float:left;
    width:100%;
    margin-bottom: 1.5rem;
	}
}

#dropdown-basic {
  background-color: transparent !important;
  border-color: transparent !important;
}
.align-text-profile {
  text-align: right;
}
.forgot-username-text-container
{
  margin: 1.5rem;
  border: 1px thin #d8dadb;
  background-color: #f3f4f3;
  border-radius: 5px;
  padding: 1.5rem !important;
}
.forgot-password-container {
  padding: 2rem !important;
  border: 1px solid #DBDBDB;
  background-color: white;
}
.feedback-padding {
  padding: 2rem !important;
}
.feedback-text-align {
  text-align: center;
}
.custom-counter {
  margin: 0;
  list-style-type: none;
  color: #555B5A;
  font-size: 1rem;
}
.custom-counter li {
  counter-increment: item;
  margin-bottom: 5px;
  text-indent: -2.9rem;
}
.custom-counter li:before {
  content: counter(item);
  margin-right: 20px;
  height: 2rem !important;
  width: 2rem !important;
  font-size: 80%;
  background-color: $PrimaryColor;
  color: white;
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 60%;
}

.custom-list-style {
  list-style-type: none;
}

.custom-list-style li:before {
  content: "\2713\0020" !important;
  padding-left: 0;
  // margin-right: 0.5rem !important;
  font-size: 1rem !important;
  background-color: #f3f4f3 !important;
  color: #555B5A !important;
}
.blue-circle {
  height: 1.5rem;
  width: 1.5rem;
}
.field-family-font{
  font-family: $fontFamily !important;
  font-size: $fontSize !important;
}
.form-styles
{
  margin-top: 2rem;
}
.headerSize{
  font-size: 1.1rem;
  font-family: $fontFamily !important;
}
.field-padding {
  padding-left: inherit !important;
}
.margin-User {
  margin-bottom: 1.5rem !important;
}
.toggle-button-margin{
  margin-bottom: 4rem !important;
}
.feedback-outer-margin {
  margin: 2rem !important;
}
.main-toggle-button {
  background-color: $PrimaryColor;
  color: white;
  border-radius: 5% !important;
  border: 1px solid $PrimaryColor !important;
  border-color: $PrimaryColor !important;
}
.toggle-width {
  width: 7rem !important;
}
.print-toggle-width {
  width: 8rem !important;
}
.sec-toggle-button {
  background-color: white !important;
  color: $PrimaryColor !important;
  border-radius: 5% !important;
  border: 1px solid $PrimaryColor !important;
}
// .chart-container {
//   overflow-x: scroll !important;
// }
.align-image{
  position: absolute;
  right: 0%;
  padding-top: 0.75rem;
}
.feedback-data-container {
  padding: 2rem 2rem 1rem;
  border: 1px solid #DBDBDB;
  background-color: $BackgroundColor;
  min-height: 30rem;
}
.feedback-data-display-container{
    padding: 1.5rem;
    background-color: white;
    // min-height: 20rem;
}
.question-field-margin {
  margin: 0 auto 1.5rem 0 !important;
}
.last-question-margin {
  margin-top: 2.5rem !important;
}
.text-float {
  text-align: right;
  background-color: $BackgroundColor;
  color: $PrimaryColor;
  font-size: $fontSize;
  font-family: $fontFamily !important;
}
.MuiPaper-elevation1 {
  box-shadow: none !important;
}
.content-border-left {
  border-left: 1px solid
}
.col .mui-custom-form .MuiFormLabel-root {
  white-space: normal !important;
  width: 133% !important;
  line-height: 1.25 !important;
}
.pdf-icon {
  font-size: 4rem !important;
}
.feedback-table-header {
  color: black !important;
  background-color: white;
  border-top: none;
}
.MuiPaper-elevation1 {
  box-shadow: none !important;
 }
 .MuiTableContainer-root {
  width: 100%;
  overflow-x : hidden !important;
 }
 .MuiTableContainer-root .MuiTableBody-root > .feedback-data :first-child{
  border-right: 1px #AAAFB9 solid !important;
  border-left: 1px #AAAFB9 solid !important;
 }
 .MuiTableContainer-root .MuiTableBody-root .desc{
  background-color: $BackgroundColor !important;
 }
  
 .MuiTableContainer-root .MuiTableBody-root > .feedback-data :last-child{
  border-right: 2px #AAAFB9 solid !important;
 }
  
 .MuiTableContainer-root .MuiTableBody-root > .feedback-data .MuiTableCell-root,
 .MuiTableContainer-root .MuiTableHead-root .MuiTableCell-root{
  border-bottom: 1px #AAAFB9 solid !important;
 }
  
 .MuiTableContainer-root .MuiTableBody-root > .total .MuiTableCell-root {
  border-bottom: none !important;
 }

//User Action Button

.user-action-btn {
  text-align: center;
  margin: 0 0.5rem;
  display: inline-block;
  font-weight: normal !important;
  button.dropdown-toggle {
    border: solid 1px rgba(0, 0, 0, 0) !important;
    &:focus {
    border: solid 1px #dddddd !important;
    }
  }
  .btn
  {
    background-color: transparent !important;
    border-width: 0px !important;
    box-shadow: none !important;
    padding: 0 !important;
  }

  
.dropdown-menu:after {
    content:"";
    position: absolute;
    right: 4px;
    top: -22px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 20px 20px 20px;
    border-color: transparent transparent transparent transparent;
    z-index:9998;
}

  .dropdown-menu
  {
    margin: 0px;
    padding: 0px;
    width: 175px !important;
    @include border-radius(0.25rem);
    z-index: 1300;
    font-size: 1rem !important; 

    .dropdown-item
    {
      color: black !important;
      border-bottom: 1px solid #dadddc !important;
      background-color: transparent !important;
      &:hover{
        background-color: #dadddc !important;
      }
      &:last-child
      {
        border-bottom-width: 0px;
      }
       a
       {
         text-decoration: none;
         display: inline-block;
         width: 100%;
         height: 100%;
          padding: 10px 24px;
         color: #000 !important;
         background-color: transparent !important;
       }
    }
  }
}

.span-text-align {
  text-align: end !important;
}
/* Forgot password ends here*/

/* media-queries starts here */
 @media only screen and (max-width: 991px) {
  .forgot-username-container
  {  
    margin-top: 0.5rem !important;
    width: 100%;
    max-width: 980px;
  }
  
  .toggle-width {
    width: 6rem !important;
  }
}


  @media only screen and (min-width : 1280px) and (max-width : 1500px) {
    .forgot-username-container {
      width: 80% !important;
    }
  }

  @media only screen and (min-width : 300px) and (max-width : 825px) {
    .custom-list-style li{
      text-indent: -2.75rem !important;
      padding-left: 0.4rem !important;
    }
  }

  @media only screen and (min-width : 768px) {
    .password-recovery-container {
      width: 55% !important;
    }
  }

  @media only screen and (max-width : 540px) {
    .overflow-table {
      overflow-x: scroll !important
    }
  }

/* media-queries ends here */
/* Forgot password ends here*/

/* Print Functionality starts here */
@media all {
  .hide-on-screen {
    display: none;
  }
}

@media print {
  .hide-on-screen {
    display: inherit;
  }
  .hide-on-print {
    display: none !important;
  } 
  .MuiTableContainer-root {
    width: 100%;
    overflow-x : auto !important;
  }
  // #page-counter:after {
  //   counter-increment: page;
  //   content: counter(page);
  // }
}
// .header, .header-space {
//   height: 70px;
// }
.footer, .footer-space {
  // height: 50px;
  padding-top: 1rem;
  display: flex;
  span {
    font-weight: bold;
  }
  #spanRight {
    right: 0 !important;
  }
}
// .header {
//   position: fixed;
//   top: 0;
// }
// .footer {
//   display: flex !important;
//   justify-content: space-between !important;
//   position: fixed;
//   bottom: 0;
// }
@page {
  margin: 1cm;
}
.pb-6 {
  padding-bottom: 4rem !important;
}
.table thead th {
  vertical-align: top !important;
}

/* Print Functionality ends here */

  /************* CSS FOR PAGES END *************/

 /*** chand start ****/
@mixin border-radius($val) {
  border-radius: $val;
  -webkit-border-radius: $val;
  -ms-border-radius: $val;
}
@mixin boxShadow($val) {
  box-shadow: $val;
  -webkit-box-shadow: $val;
  -ms-box-shadow: $val;
}
.MuiFormLabel-asterisk
  {
    color: $ErrorColor !important;
  }
  .top-navbarr {
    display: block;
    padding: 0 10px;
    margin: 0;
    background: #FFFFFF !important;
    @include boxShadow(1px 0px 8px rgba(0, 0, 0, 0.2) !important);
    position: fixed;
    top: 0;
    z-index: 99;
    width: 100%;
    height: 65px;
    .MuiToolbar-regular {
      padding-right: 0px;
    }
    .top-logo-bar {
      height: 65px;
      width: 50%;
      overflow: hidden;
      display: flex;
      align-items: center;
      a {
        display: inline-block;
        height: 60px;
        padding-right: 12px;
        img {
          height: 55px;
          width: auto;
        }
      }
    .logo-text {
      font-weight: bold;
      color: #222;
      line-height: 1;
      font-size: 16px;
    }
  }
  .iox-label{
    font-weight: bold;
    color: #222;
    line-height: 1;
    font-size: 16px;
    margin-top: 25px;
  }
  }

  // content wrapper start
  .custom-box-shadow{
    @include boxShadow(1px 0px 8px rgba(73, 73, 73, 0.1) !important);
  }
  .welcome-msg{
    position: relative;
    background-color: rgb(255, 255, 229);
    box-shadow: 1px 1px 8px 0px #ccc;
    -webkit-box-shadow: 1px 1px 8px 0px #ccc;
    -moz-box-shadow: 1px 1px 8px 0px #ccc;
    font-size: 0.80em;
    color: #444;
    .close-msg-box{
      position: absolute;
      right: 9px;
      top: calc(50% - 10px);
      z-index: 999;
      width: 20px;
      height: 20px;
      color: #707070;
      font-weight: 600;
      font-size: 18px;
      cursor: pointer;
      text-indent: -99px;
      overflow: hidden;
    }
    .close-msg-box:after, .close-msg-box:before {
      content: "";
      height: 2px;
      width: 14px;
      display: inline-block;
      position: absolute;
      background: #666;
      left: 2px;
      @include border-radius(1px);
      border-radius: 1px;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      top: 9px;
    }
    .close-msg-box:before {
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
    }
  }
  .content-wrapper{
    padding: 0 20px 10px;
    margin-bottom: 20px;
    min-height: calc(100vh - 200px);
    .tabs-container {
        padding: 0px !important;
    }
    .MuiFormLabel-root, .custom-mui-label{
      color: $PrimaryColor !important;
      font-size: 1.11em !important;
      white-space: nowrap;
      font-weight: 700;
    }
    .custom-mui-label{
      color: $PrimaryColor !important;
      font-size: 0.80em !important;
      white-space: nowrap;
      font-weight: 700;
      margin-bottom: 6px;
    }
    a{
      color: #007bff;
    }
    .mui-custom-form .MuiInput-input{
      font-size: 14px;
    }
    .MuiMenuItem-root{
      font-size: 0.85em!important;
    }
  }
  .MuiMenuItem-root{
    font-size: 0.85em!important;
  }
  .questionnaire-area{
    font-size: 0.85em;
    .txt-quest{
      font-weight: 700;
      color: #666666;
      margin-bottom: 5px;
    }
    ul{
      padding-left: 18px;
      li{
        color: #666666;
        margin-bottom: 10px;
      }
      li:last-child{
        margin-bottom: 0;
      }
    }
    .radio-label{
      color: #666666;
    }
  }
  .page-header {
    margin: 0.5rem 0 0.25rem;
  }
  .tab-header, .page-header {
    color: #ffffff !important;
    padding: 8px 0 5px;
    &:after {
        content: "";
        clear: both;
        display: block;
    }
    &.flex-theader {
      padding: 7px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .tab-heading, .page-heading {
      color: #000000;
      padding: 10px 0 5px;
      font-weight: 600;
      font-size: 1.135em;
      margin-bottom: 0px;
      text-transform: none;
      line-height: 1.4;
    }
    .txt-instruction{
      color: #aaa;
      font-size: 0.95em;
    }
    .page-heading {
        font-size: 1.28rem;
    }
    .th-btnGroup {
      .btn {
        margin: 3px 0 0 10px;
      }
    }
    .float-right {
      .btn {
        margin-top: 3px;
      }
    }
  }

/* tab Panel Block starts Here */
.tab-body, .tab-body-bordered {
  color: #000;
  background-color: #fff;
  margin-top: 0.35em;
  @include border-radius(5px);
}
.tab-body-bordered {
  border: solid 1px #ececec;
  @include boxShadow(0 2px 10px rgba(22,38,56,.04));
}

.frm-app-registration {
margin: 0 !important;
.mui-custom-form .MuiInput-root{
  margin-top: 0!important;
}
}

// CNDT rows start
.cndt-columns {
  margin: 0 16px 20px 16px;
}
.cndt-row,
.cndt-columns, .form-inner-block {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.cndt-row {
  margin-right: -5px;
  margin-left: -5px;
  max-width: none;
}
.cndt-row > .cndt-col,
.cndt-row > [class*="cndt-col-"] {
  position: relative;
  width: 100%;
}
.cndt-row > .cndt-col,
.cndt-row > [class*="cndt-col-"], .cndt-row-wrapper {
  padding-right: 5px;
  padding-left: 5px;
}

.cndt-col-1 {
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.cndt-col-2 {
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.cndt-col-3, .columns-data .md-block-3cols {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.cndt-col-4,
.cndt-3-col {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.cndt-col-5 {
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.cndt-col-6,
.cndt-2-col, .columns-data .field-md {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.cndt-col-7 {
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.cndt-col-8 {
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.cndt-col-9, .field-lg {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}

.cndt-col-10 {
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.cndt-col-11 {
  -ms-flex: 0 0 91.666667%;
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.cndt-col-12, .field-xl, .columns-data .field-xl {
  -ms-flex: 0 0 100% !important;
  flex: 0 0 100% !important;
  max-width: 100% !important;
}
// CNDT rows end

// button start
.th-btnGroup .btn {
  margin-left: 5px;
}

.btn {
  color: white !important;
  font-weight: 600 !important;
  font-size: 0.85rem !important;
  padding: 8px 15px !important;
  border-width: 1px !important;
  @include border-radius(5px !important);
  @include boxShadow(6px 3px 12px rgba(39,68,99,.3)!important); 
  text-transform: uppercase;
  position: relative;
  text-decoration: none !important;
  .fa {
    font-size: 12px;
    margin-right: 5px;
  }
}
.btn:before, .btn-add-select:after, .icon-filter:before {
  font-family: "FontAwesome";
  font-size: 0.83rem;
  font-weight: 400;
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translate(0, -50%);
  -webkit-transform: translate(0, -50%);
}
.btn-ic {
  padding: 8px 15px 8px 30px !important;
  }
.btn-save, .btn-add, .btn-success, .btn-approve, .btn-revoke, .btn-next, .btn-verify, .btn-login-new {
  background-color: $SuccessColor !important;
  border-color: #00A89D !important;
  &:hover, &:focus {
    // background-color: #00A89D !important;
    background-color: $SuccessColor !important;
  }
}
.btn-reject {
  background-color: $RejectColor !important;
  border-color: #cc0000 !important;
  &:hover, &:focus {
    background-color: #cc0000 !important;
  }
}
.btn-delete, .btn-default {
  background-color: #fff !important;
  border:solid 1px $PrimaryColor !important;
  color: $PrimaryColor !important;
  &:hover, &:focus {
    background-color: #f8f8f8 !important;
  }
}
.btn-cancel {
  background-color: $PrimaryColor !important;
  border-color: $PrimaryColor !important;
  &:hover, &:focus {
      background-color: #2f5b8a !important;
    }
}
.btn-reset, .btn-send-new-code, .btn-resend-email {
  border-color: #ffffff !important;
  padding-left: 30px !important;
  color: $PrimaryColor !important;
  &:hover, &:focus {
    background-color: #f2f2f2 !important;
  }
  i {
    display: none;
  }
}
.btn-transparent {
  color: $PrimaryColor !important;
}
.btn-save:before, .btn-approve:before {
  content: "\f00c";
}
.btn-next:before {
  content: "\f061";
}
.btn-reject:before {
  content: "\f00d";
}
.btn-revoke:before {
  content: "\f021";
}
.btn-add:before {
  content: "\f067";
}
.btn-delete:before {
  content: "\f1f8";
  font-size: 0.9rem;
}
.btn-reset:before {
  content: "\f0e2";
}
.btn-verify:before {
  content: "\f00c";
}
.btn-send-new-code:before {
  content: "\f1d8";
}
.btn-resend-email:before {
  content: "\f0e2";
}
.btn-login-new:before {
  content: "\f023";
}
.info-msg:before {
  font-family: "FontAwesome";
  content: "\f05a";
  font-size: 0.9rem;  
  margin-right: 8px;
  margin-top: 2px;
}
.icon-filter{
  width: 32px;
  height: 40px;
  border-right: 0.80px solid #707070;
  cursor: default;
  &:before{
    content: "\f0b0";
    font-size: 2rem !important;
    left: -4px;
    -webkit-text-stroke: 1px #707070;
    -webkit-text-fill-color: #fff;
  }
}
.float-center{
  display: flex;
  justify-content: center;
}
//button end
// form control start
.mui-custom-form .Mui-disabled.MuiInputBase-root, .disabled-form textarea, .disabled-form input {
  background: #f4f4f4 !important;
  border-color: #eee !important;
}
.mui-custom-form .Mui-disabled .MuiInput-input {
  color:#545955!important;
  background: #f4f4f4 !important;
}
// form control end
/*-- Custom alert start --*/
.custom-alert-box {
  .btn-primary{
    margin: 0;
    width: auto;
  }
  .MuiDialog-paper {
    min-width: 400px;
  }
  .MuiDialogContent-root {
    padding-top: 30px !important;
    min-height: 5rem;
    .MuiTypography-body1 {
      font-size: 0.95rem;
      text-align: center;
      color: #333333;
    }
  }
  .MuiDialogActions-root {
    justify-content: center !important;
    padding-bottom: 30px !important;
    .btn {
        //font-size: 0.92rem !important;
        padding: 7px 20px !important;
      }
  }
}

/*-- Custom alert end --*/
// content wrapper end
/*** Styling Data Table start ***/
.table-wrapper {
  width: 100% !important;
  overflow-x: auto !important;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  // margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding: 0 !important;
  @include border-radius(4px 4px 0 0);
}
.table-wrapper-2, .tabs-wrapper-2 {
    margin: 15px 15px 15px 20px;
}
.customDataTable {
  //min-width: 750px;
  min-height: 150px;
  .sa-exception-tbl & {
    min-height: 100px;
  }
  thead {
    th {
      font-size: 0.9655rem !important;
      .MuiSvgIcon-root {
        opacity: 0;
        margin: 0;
      }
      &:hover {
        .MuiSvgIcon-root {
          opacity: 1;
        }
      }
      .MuiCheckbox-root {
        .MuiSvgIcon-root {
          color: #fff !important;
          opacity: 1;
        }
      }
    }
  }
  .MuiCheckbox-root {
    padding: 0px;
  }
  .MuiTableCell-root,
  .MuiTableRow-footer {
    padding: 5px 8px;
  }
  .MuiTableHead-root {
    background-color: $PrimaryColor;
    .MuiTableSortLabel-root {
      color: #fff;
      font-weight: 600;
      white-space: nowrap;
      &.MuiTableSortLabel-active {
      .MuiSvgIcon-root {
        color: #fff !important;
      }
    }
    }
  }
  .table-pagination {
    width: 35px;
    min-width: 35px;
    max-width: 35px;
    span {
      width: 25px;
      height: 25px;
      display: inline-block;
      @include border-radius(50%);
      background-color: #545955;
      text-align: center;
      color: #fff;
      line-height: 25px;
    }

  }
  .MuiTableCell-paddingCheckbox{
    width: 35px !important;
    min-width: 35px !important;
    max-width: 35px !important;
    padding: 5px 2px 5px 8px !important;
  }
  .MuiTableFooter-root {
    .MuiIconButton-root {
      padding: 12px;//5px 2px
    }
  }
  .MuiTablePagination-toolbar {
    min-height: 40px !important;
  }
  .MuiTableFooter-root .MuiIconButton-root:focus {
    outline: none;
    background-color: #DADDDC !important;
  }
}
.set-tbl-Space {
  th {
    max-width: 220px;
     .MuiTableSortLabel-root {
       white-space: pre-wrap !important
     }
  }
}
.wip {
  margin-top: 20%;
  margin-left: 35%;
  color: #bfa98e;
}
a, .cndt-link, .cndt-link a, table tr td a {
  color: $PrimaryColor !important;
  text-decoration: underline !important;
}
.customDataTable {
  .MuiTableRow-root {
    a {
      display: contents;
      width: 100%;
      text-decoration: none !important;
    }
  }
  .has-link {
    padding: 0px;
    a {
      padding: 7px 10px;
      color: $PrimaryColor !important;
      cursor: pointer;
      display: inline-block;
      width: 100%;
      height: 100%;
      text-decoration: underline !important;
      .clm-inquiry-exceptionData & {
        padding: 0 !important;
      }
    }
  }
}
.th-brd-r {
  border-right: solid 1px #e0e0e0;
}
table .MuiSvgIcon-root {
  .cndt-col-6 & {
  width: 0.8em;
  }
}
.hide-elm, .hide-spn span.MuiTypography-body1 {
  display: none;
}
.opacity-0, .hidden-lbl {
  opacity: 0;
}
.dib, .block-elm, .MuiTable-root caption {
  display: inline-block !important;
}
.dif, .dis-flex, .flex-block, .container.custom-container {
  display: flex !important;
}

.MuiCheckbox-colorPrimary, table tr td {
  .MuiSvgIcon-root {
    color: $PrimaryColor !important;
  }
}
.th-min-130 tr th {
  min-width: 130px !important;
}
.Mui-disabled .MuiSvgIcon-root, .Mui-disabled .MuiTypography-root,
table tr td .MuiIconButton-root.Mui-disabled .MuiSvgIcon-root {
  color: $disabledColor !important;
  background-color: transparent !important;
}
/*** Styling Data Table end ***/
.hide-controls .user-actions {
  display: none;
}
/*** Footer start ***/
.page-footer {
  padding:0.5rem 1rem;
  margin-top: 10px;
  @media only screen and (max-width : 767px) {
    -webkit-flex-wrap: wrap-reverse;
    flex-wrap: wrap-reverse;
    & > div {
      -webkit-flex: 0 0 100%;
      flex: 0 0 100%;
      text-align: center;
      padding-bottom: 20px;
    }
  }
}
.main-footer {
  width: 100%;
  position: relative;
}
.footer-text {
  font-size: 0.8em;
  color: rgb(102, 102, 102);
}

/*--footer-nav-items--*/
.footer-nav-items span:hover a {
  text-decoration: underline !important;
  color: $PrimaryColor !important;
}
.footer-nav-items {
  overflow: hidden;
}
.footer-nav-items span {
  line-height: 1;
  display: inline-block;
}
.footer-nav-items span a {
  border-right: 1px solid #007bff;
  padding: 0 5px;
  color: rgb(0, 71, 186) !important;
  font-size: 0.713em;
  font-weight: 600;
  text-decoration: none!important;
}
.footer-nav-items span:last-child a,
.footer-nav-items span:last-child {
  border-right: 0;
}
/*--footer-nav-items-end--*/
// bottmo logo
.footer-brand {
  width: 230px;
}
.footer-content {
  width: calc(100% - 230px);
  max-width: calc(100% - 230px);
  @media (max-width: 768px) {
    width: 100%;
    max-width: 100%;
  }
}
/*** Footer end ***/
.custom-panel {
  border-radius: 5px 5px 0 0;
  position: relative;
  .panel-header {
    padding: 8px 15px !important;
    background-color:$PrimaryColor !important;
    color: #ffffff;
    font-size: 1.2rem;
    font-weight: 600;
    border-radius: 5px 5px 0 0;
  }
  .close-modal-bx{
    position: absolute;
    right: 9px;
    top: 8px;
    z-index: 999;
    width: 20px;
    height: 20px;
    color: #fff;
    font-weight: 700;
    font-size: 18px;
    cursor: pointer;
  }
}

/*** file upload start ***/
.upload-btn-area{
  display: flex;
  .btn-upload{
    background-color: $PrimaryColor !important;
    border-color: $PrimaryColor !important;
    width: auto;
    padding: 6px 15px!important;
    line-height: 2;
    display: inline;
  }
  .upload-file-name{
    margin: 5px;
    font-size: 0.80em;
  }
}
/*** file upload end ***/

/*** search area start ***/
#frm-app-search{ 
  border: 0.8px solid #707070;
  border-radius: 5px;
}
.mui-custom-form.txt-search .MuiInput-root{
  border: 0px solid #707070!important;
  //border-radius: 5px 0px 0px 5px!important;
  padding-left: 0px;
  margin: 0;
}
.txt-select{
  padding-right: 0!important;
}
.mui-custom-form.txt-select .MuiInput-root{
  border: none;
  border-left: 0.80px solid #707070!important;
  border-radius: 0px 5px 5px 0px!important;
  margin: 0;
}
/*** search area end ***/
.txt-confirm{
  font-size: 0.90em;
}
.date-area{
  .block{
    color: #707070;
    font-size: 0.85em;
    .inline-text{
      color: $PrimaryColor ;
      font-weight: 600;
    }
  }
}

label.Mui-required {
  padding-left: 10px;
  .MuiFormLabel-asterisk  {
    position: absolute;
    left: -5px;
    top: calc(50% - 9px);
  }
}
.Mui-required-lbl {
    font-weight: 700;
    position: relative;
    left: -2px;
    top: 1px;
}
// member login start
.container.custom-container{
  max-width: 900px;
  height: auto;
  min-height: calc(100vh - 140px);
  display: flex;
  align-items: center; 
}
.mem-link-right{
  width: 100%;
  margin-right: 30px;
  padding-top: 1em;
}
.mem-login-link-inner{
  border: 1px solid #eee;
  border-radius: 5px;
  .link-block{
    border-bottom: 1px solid #eee;
    display: flex;
    align-items: center;
    .link-icon{
      width: 44px;
      height: 44px;
      //background-color: red;
    }
    .link-text{
      padding-left: 45px;
      position: relative;
      .link-lbl{
        color: #000;
        font-size: 14px;
        font-weight: 600;
      }
      a{
        font-size: 13px;
      }
    }
    &:last-child{
      border-bottom: 0px solid #ccc;
    }
  }
}

.admin-login-wrapper{
  display: flex;
  align-items: center;
  color: $PrimaryColor ;
  .icon-admin-login{
    margin-right: 8px;
  }
  .link-text{
    font-size: 14px;
    font-weight: 600;
    a{
      text-decoration: none!important;
    }
  }
}

.icon-pos:before {
  font-family: "FontAwesome";
  position: absolute;
  left: 0px;
  transform: translate(0px, -50%);
  top: 20px;
}

.icon-admin-login:before{
  font-family: "FontAwesome";
  content: "\f023";
  font-size: 22px;
}

.icon-exist-mem:before{
  font-family: "FontAwesome";
  content: "\f007";
  font-size: 35px;
}
.icon-new-mem:before{
  font-family: "FontAwesome";
  content: "\f234";
  font-size: 29px;
}
.icon-app-dev:before{
  font-family: "FontAwesome";
  content: "\f0c0";
  font-size: 28px;
}
// member login end
.carousel-inner{
  min-height: 405px;
  max-height: calc(100vh - 185px);
}
#carousal .carousel-caption{
  bottom: 18%;
}

.left-bottom-logo{
  img{
    padding: 0!important;
  }
}
/*** chan media query start ***/
  @media only screen and (min-width: 370px) and (max-width: 770px) {
       
    .main-footer{
      margin-bottom: 25px;
      p{
        padding: 0!important;
      }
    }
    
  }
  @media only screen and (min-width: 370px) and (max-width: 649px) {
    .mui-custom-form.mt-block{
      margin-top: 15px!important;
    }
    .mui-custom-form{ 
      .MuiTextField-root{
        &.add2-block{
          padding-top: 0!important;
        }
      }
    }
  }

  @media only screen and (min-width: 650px) and (max-width: 768px) {
    /*.makeStyles-paper-1{
      width: 500px!important;
    }*/
    
  }

  @media only screen and (min-width: 370px) and (max-width: 767px) {
    .mem-link-right{
       margin-left: 15px;
       margin-right: 15px;
    }
    .py-5.redu-mar{
      padding-top: 10px!important;
    }
  }
  @media only screen and (min-width: 100px) and (max-width: 299px) {
    .top-navbarr{
      padding: 0;
      .top-logo-bar{
        width: auto;
      }
    }
  }

  @media only screen and (min-width: 300px) and (max-width: 575px) {
    
    .left-bottom-logo.mr-4{
      margin-right: 0rem!important;
    }
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    
    .left-bottom-logo.mr-4{
      margin-right: 0rem!important;
    }
  }



  @media only screen and (max-width: 1024px){
    .carousel-caption{
      bottom: 20%!important;
    }
    .carousel-indicators {
      margin: 0 4% 30% !important;
    }
    .carousel-inner {
      height: 407px;
    }
    .py-5.redu-mar{
      padding-top: 8px!important;
      padding-bottom: 0px!important;
    }
  }
  @media only screen and (max-width: 812px){
    .carousel-caption{
      bottom: 10%!important;
    }
  }
  @media only screen and (max-width: 767px){
    .carousel-caption{
      bottom: 5%!important;
    }
    .carousel-indicators {
      margin: 0 4% 30% !important;
    }
    .carousel-inner {
      height: 407px;
    }
    .mob-main-head, .welcome-msg{
      margin-left: 15px;
      margin-right: 15px;
    }
    .content-wrapper .welcome-msg{
      padding-right: 40px!important;
    }
    .top-navbarr{
      padding: 0 20px;
      .top-logo-bar{
        width: auto;
      }
      .logo-text{ 
        display: block;
      }
    }
    #frmModal{
      width: 600px!important;
    }
    /*** search area start ***/
    #frm-app-search{ 
      border: 0;
      border-radius: 5px;
    }
    .mui-custom-form.txt-search .MuiInput-root{
      border: 0.80px solid #707070!important;
      //border-radius: 5px 0px 0px 5px!important;
    }
    .mui-custom-form.txt-select{
      padding-right: 16px!important;
      margin-top: 10px!important;
    }
    .mui-custom-form.txt-select .MuiInput-root{
      border: 0.80px solid #707070!important;
      border-radius: 5px!important;
    }
    .tabs-inner-container .plr-none{
      padding-left: 0!important;
      padding-right: 0!important;
    }
    .icon-filter{
      width: 40px;
      &:before{
        left: 7px;
      }
    }
    /*** search area end ***/
    .mob-main-head h2{
      font-size: 1.5rem !important;
    }
    .dialog-textbox.mui-custom-form .MuiFormLabel-root{
      white-space: normal;
    }
    .custom-alert p {
      padding: 3px 0px 0px 0px!important;
    }
  }
  @media only screen and (max-width: 736px){
    .carousel-caption {
      bottom: 45% !important;
    }
    .carousel-indicators {
      margin: 0 4% 20% !important;
    }
    #frmModal{
      width: 600px!important;
    }
  }
   @media only screen and (max-width: 667px){
    .carousel-caption{
      bottom: 57%!important;
    }
    .carousel-indicators {
      margin: 0 4% 30% !important;
    }
    .carousel-inner {
      height: 318px;
      min-height: 318px;
    }
    #frmModal{
      width: 500px!important;
    }
  }
  @media only screen and (max-width: 414px){
    .carousel-caption {
      bottom: 17% !important;
    }
    #frmModal{
      width: 370px!important;
    }
    .table-wrapper .MuiTablePagination-caption{
      display: none;
    }
    .table-wrapper .MuiTablePagination-input{
      display: none;
    }
  }
  @media only screen and (max-width: 375px){
    .carousel-caption {
      bottom: 3% !important;
    }
    #frmModal{
      width: 300px!important;
      //left: 52%!important;
    }
    .btn{
      font-size: 0.80rem !important;
      padding: 8px 10px !important;
    }
    .btn-ic {
      padding: 8px 8px 8px 25px !important;
    }
    
  }

  @media only screen and (max-width: 320px){
    .top-navbarr{
      padding: 0 2px;
      .top-logo-bar{
        width: auto;
      }
    }
    .carousel-inner {
      height: 220px;
   }
   .carousel-caption {
    bottom: 18% !important;
    h3{
      font-size: 1.20rem;
    }
    }
    .py-5.redu-mar{
      padding-top: 10px!important;
    }
    .mem-link-right{
      margin-right: 10px;
      margin-left: 10px;
    }
    #frmModal{
      width: 270px!important;
    }
    .upload-btn-area{
      label{
        width: 171px;
      }
      .upload-file-name{
        margin: 0;
      }
    }
    
  }
  @media only screen and (max-width: 280px){
    #frmModal{
      width: 250px!important;
    }
    .upload-btn-area{
      label{
        width: 180px;
      }
      .upload-file-name{
        margin: 0;
      }
    }
  }
  /*** chan media query end ***/
.visuallyhidden {
    border: 0 none;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    padding: 0!important;
}
.left-bottom-logo{
  display: flex;
  align-items: center;
  .poweredBy {
    font-size: 12px;
    font-weight: 600;
    padding-top: 7px;
    padding-left: 10px;
  }
  
}

.wip {
  margin-top: 20%;
  margin-left: 35%;
  color: #bfa98e;
}
.add2-mar{
  margin-top: 31px!important;
}

.questionnaire-area{
  overflow-wrap: break-word;
  word-wrap: break-word;
  padding-right: 15px;
}

.custom-dialog.dialog-520 .MuiDialog-paperWidthSm{
  padding: 0!important;
  
}
.dialog-inner-cont{
  padding: 2%;
}
.dialog-inner-cont .WithStyles\(ForwardRef\(DialogContent\)\)-root-5{
  padding-bottom:0px!important;
}
.dialog-textbox.mui-custom-form .MuiFormLabel-root{
  font-weight: 600;
  color: $PrimaryColor !important;
  font-size: 1.11em!important;
  margin-bottom:  0;
}
.custom-alert p{
  margin-bottom: 0.50em;
  font-size: 0.85em;
}
.custom-success-container{
  @include border-radius(5px);
  border: solid 1px #ececec;
  @include boxShadow(0 2px 10px rgba(22,38,56,.04));
  margin-top: 60px;
}

.dialog-err-msg .w-100{
  margin-left: 23px;
  margin-right: 23px;
}
.dialog-inner-cont .MuiDialogContent-root:first-child {
  padding-top: 0;
}
.dialog-inner-cont .MuiDialogContent-root{
  min-height: 5rem;
}

// new
.bold-600, .fw-600 {
  font-weight: 600 !important;
}
.wid-100, .block-elm {width: 100% !important}
.row {max-width: none;}
.main-content {
    min-height: calc(100vh - 82px);
    position: relative;
    padding: 0px;
    margin: 0;
    z-index: 12;
    overflow: scroll;
}
.page-container {
  width: 100%;
  min-height: calc(100vh - 110px);
  padding-top: 65px;
  box-shadow: 2px -3px 15px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 2px -3px 15px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 2px -3px 15px rgba(0, 0, 0, 0.2);
}
.container.custom-container, .page-container .login_block {
  display: flex;
  -webkit-display: flex;
  align-items: center;
  -webkit-align-items: center;
}

//User Actions css
.set-top-m2 {
  position: relative;
  top: -2px;
}
.user-action-btn {
  text-align: left;
  min-width: 180px;
  .btn {
    background-color: transparent !important;
    border-width: 1px !important;
    color: #000 !important;
    font-weight: bold;
    @include boxShadow(none !important);
    padding: 8px !important;
    .MuiSvgIcon-root {
      width: 1.2em;
      height: 1.2em;
      left: -2px;
      @extend .set-top-m2;
    }
  }
  .dropdown-menu {
    margin: 0px;
    padding: 0px;
    @include border-radius(0rem 0rem 0.25rem 0.25rem);
    width: 100%;
    .dropdown-item {
      border-bottom: 1px solid #dadddc !important;
      background-color: transparent !important;
      padding: 0px;
      &:last-child {
        border-bottom-width: 0px;
      }
      a {
        text-decoration: none !important;
        display: inline-block;
        width: 100%;
        height: 100%;
        padding: 10px 15px;
        color: #000 !important;
        background-color: transparent !important;
        -webkit-transition: all linear 0.1s;
        transition: all linear 0.1s;
        &:hover {
        background-color: #dadddc !important;
        padding: 10px 15px 10px 20px;
      }
      }
    }
  }
}

// Left Navigation CSS
.side-drawer, .MuiDrawer-paper, .main-content, .top-navbar {
  transition: none !important;
  transition-timing-function: none !important;
}
.makeStyles-drawerPaper-7, .side-drawer, .jss7 {
  width: 255px !important;
}
.top-brand-border {
  left: 45px;
  top:0;
  width: 206px;
  height: 6px;
  position: fixed;
  transform: skew(45deg);
  -webkit-transform: skew(45deg);
  background: #ff8700;
  z-index: 1;
}
.side-drawer {
  .MuiPaper-root {
    padding: 0 0 65px;
    background: none !important;	
    z-index: 9 !important;
    top: 22px;
    height: calc(100% - 20px);
    width: 255px;
  }
}
#sideNav {
  .dashBoard {
    padding-left: 10px !important;
    padding-right: 15px !important;
    min-height: 36px;
  }
  .side-menu-icon {
    width: 30px;
    text-align: center;
  }
  .fa {
    font-size: 24px;
    color: #aaafb9;
  }
  .fa-20 {
    font-size:20px !important;
  }
}
.activeNav, .activeNav:hover {	
  width: 100%;
  display: inline-flex;	
  align-items: center;	
  padding-left: 15px;	
  @include border-radius(5px !important);
  position: relative;	
}	

.activeNav *, .activeNav:hover *, .side-menu-list .activeNav .MuiTypography-root,
.side-menu-list.activeNav .MuiTypography-root {	
  color: #ffffff !important;	
}	
.activeNav:after {
    content: "";	
    position: absolute;	
    right: 0px;	
    top: 3px;
    height: calc(100% - 6px);	
    width: 4px;	
    background: #ff8700;	
    @include border-radius(4px);
}	

.MuiList-root{ position: relative;}
.MuiIcon-root {
    width: 1.3em !important;
    height: 1.3em !important;
    font-size: 1.7rem !important;
    text-align: center;
    line-height: 1.3 !important;
    text-indent: 0 !important;

    & & .MuiListItem-button:after {
    content: "";
    position: absolute;
    }
}
.side-menu-list {
  .activeNavLi {
    .MuiListItem-button, .MuiListItem-button:hover {
    background-color:$PrimaryColor !important;
    @include border-radius(5px);
    .MuiTypography-root {
    color: #ffffff !important;
    text-decoration: none !important;
    }
  }
  }
  & > .MuiListItem-gutters, .dashBoard {
      padding:4px 10px !important;
      min-height: 36px;
  }
}
.sideMenu-active-Li{
  background-color:$PrimaryColor !important;	
  color: #ffffff !important;	
}
.activeNav {	
  background-color:$PrimaryColor !important;	
  color: #ffffff !important;	
  @include border-radius(5px);
}	
 
.side-drawer, .MuiDrawer-paper, .main-content, .top-navbar {
  transition: none !important;
  transition-timing-function: none !important;
}
.clm-inquiry-tabData, .clm-inquiry-exceptionData {
  transition: width 150ms !important;
  transition-timing-function: cubic-bezier(0.1, 0.4) !important;
}
.left-bottom-brand {
  display: inline-block;
}
.MuiListItemText-root {
  padding:4px 8px !important;
  margin: 0 !important;
  cursor: pointer;
  a {
    text-decoration: none;
  }
}
label#monthlyID-label{
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden; 
  padding: 0; 
  position: absolute;
  width: 1px;
  // display: none;
}
label#quarterlyID-label{
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  // display: none;
}
.leftNavOff {
  .main-content {
      width: calc(100% - 150px) !important;
  }
  .side-drawer, .MuiDrawer-paper {
    width: 150px !important;
  }
  .side-drawer {
    overflow-y: auto;
  }
 /*  .MuiDrawer-paper {
    .MuiListItemText-root,
    .MuiSvgIcon-root {
      display: none;
    }
     }
        */
  .side-drawer .MuiPaper-root {
    height: calc(100% - 90px);
    padding: 0;
  }
  .left-bottom-logo {
    &.bottom {
      position: fixed;
      width: 75px;
      .poweredBy {
      display: none;
      }
      .left-bottom-brand {
        width: 45px;
        overflow: hidden;
        position: relative;
        height: 45px;
        img {
          position: absolute;
          top: 0;
          right: -5px;
        }
      }
    }
  }
  .activeNav{
    &:after {
      right: -17px;
    }
  }
  #sideNav {
    overflow: initial;
    overflow-y: auto;
    padding-right: 15px;
    .MuiCollapse-wrapper {
      display: none;
    }
  }
  .MuiList-padding, .db-space {
    padding-right: 2px !important
  } 
}

.MuiList-padding {
    padding: 0 5px 5px !important;
}
.form-wrapper-iox {
  padding: 8px 5px;
  display: flex;
  flex-wrap: wrap;
  .mui-custom-form, .custom-form-wrapp-inner {
    margin: 8px 10px !important;
    width: calc(25% - 20px);
    .MuiInputLabel-formControl {
      position: absolute !important;
      left: 0;
      top: 0;
      transform: translate(0, 1.5px) scale(0.79);
      -webkit-transform: translate(0, 1.5px) scale(0.79);
      transform-origin: top left;
      -webkit-transform-origin: top left;
    }
    .MuiInput-root {
      margin-top: 23px !important;
    }
    .MuiFormLabel-root {
      font-size: 1.22rem !important;
    }
    &.field-md {
      width: calc(50% - 20px) !important;
      max-width: calc(50% - 20px) !important;
      min-width: calc(50% - 20px) !important;
    }
    &.field-lg {
      width: calc(75% - 20px) !important;
      max-width: calc(75% - 20px) !important;
    }
    &.field-xl {
      width: calc(100% - 20px) !important;
      max-width: calc(100% - 20px) !important;
    }
    @media (max-width: 1024px) {
      &.w-100 {
        width: 100% !important;
      }
      &.md-field-md, &.md-field-lg { 
        width: calc(66.66% - 20px) !important;
        max-width: calc(66.66% - 20px) !important;
        min-width: calc(66.66% - 20px) !important;
        -ms-flex: 0 0 calc(66.66% - 20px) !important;
        flex: 0 0 calc(66.66% - 20px) !important;
      }
      &.md-field-xl {
        width: calc(100% - 20px) !important;
        max-width: calc(100% - 20px) !important;
        -ms-flex: 0 0 calc(100% - 20px) !important;
        flex: 0 0 calc(100% - 20px) !important;
      }
    }
  }
  &.form-3-column {
    .mui-custom-form, .custom-form-wrapp-inner {
      width: calc(33.333% - 20px) !important;
    }
    .custom-form-wrapp-inner {
    .mui-custom-form {
      width: calc(49.5% - 20px) !important;
      @media (max-width: 768px) {
        width: calc(50% - 10px) !important;
      }
    }
  }
  }
  .set-form-wrapper & {
      margin: 0 -10px;
      max-width: none;
  }
}
.form-wrap-inner, .tbody-space, .container-space {
    padding: 0 16px 1rem !important;
}
/*-- Responsive SCSS starts here */
@media screen and (max-width : 1200px) {
.form-wrapper-iox {
  .mui-custom-form, .custom-form-wrapp-inner {
    width: calc(33.33% - 20px) !important;
  }
  .mui-custom-form.field-md {
    width: calc(66.33% - 20px) !important;
    max-width: calc(66.33% - 20px) !important;
    min-width: calc(66.33% - 20px) !important;
  }
}
}
@media screen and (min-width : 769px) and (max-width : 1160px) {
  .form-wrapper-iox {
    .mui-custom-form {
    &.md-field-md {
      width: calc(50% - 20px) !important;
      max-width: calc(50% - 20px) !important;
      min-width: calc(50% - 20px) !important;
      -ms-flex: 0 0 calc(50% - 20px) !important;
      flex: 0 0 calc(50% - 20px) !important;
    }
    &.field-lg, &.md-field-lg {
      width: calc(66.33% - 20px) !important;
      max-width: calc(66.33% - 20px) !important;
      -ms-flex: 0 0 calc(66.33% - 20px) !important;
      flex: 0 0 calc(66.33% - 20px) !important;
    }
    }
  }
}
@media screen and (max-width: 768px) {
  .form-wrapper-iox, .form-wrapper-iox.form-3-column, .form-2-column {
    .mui-custom-form, .custom-form-wrapp-inner {
      width: calc(50% - 20px) !important;
      max-width: calc(50% - 20px) !important;
    }
  }
  .form-wrapper-iox {
    .mui-custom-form {
      &.field-md, &.field-lg, &.md-field-lg {
        width: calc(100% - 20px) !important;
        max-width: calc(100% - 20px) !important;
        -ms-flex: 0 0 calc(100% - 20px) !important;
        flex: 0 0 calc(100% - 20px) !important;
      }
    }
  }
  .h1-md {
    font-size: 1.4em !important;
  }
}
@media screen and (max-width: 576px) {
.form-wrapper-iox, .form-wrapper-iox.form-3-column, .form-2-column {
  .mui-custom-form, .custom-form-wrapp-inner {
      width: calc(100% - 20px) !important;
      max-width: calc(100% - 20px) !important;
    }
  }
  .h1-md {
    font-size: 1.3em !important;
  }
}
.ol-quest {
    padding: 0;
    list-style-position: inside;
    line-height: 1.8;
    li {
      padding-bottom: 10px;
    }
}
.set-sub-radio {
  margin-left: -10px;
  label.radio-label {
    margin-top: 9px;
    font-weight: 700;
    float: left;
  }
  .MuiRadio-root {float: left;}
}
.sub-radio {
  margin-top: 10px;
  font-size: 13px;
  overflow: hidden;
  label {
    margin: 0px;
  }
  .MuiSvgIcon-root {
    width: 20px;
    height: 20px;
  }
  .MuiCheckbox-root {
    padding: 0px 4px 0px 0px;
    position: relative;
  }
.Mui-checked {
    .MuiSvgIcon-root {
      color: $PrimaryColor;
    }
    &:hover {
      background-color: rgba(39, 68, 99, 0.08);
    }
  }
  .MuiFormControlLabel-label {
    font-size: 13px;
    font-weight: 400;
    padding-top: 2px;
    padding-left: 2px;
    color: #545955;
  }
  .MuiFormGroup-row {
    margin-left: -10px;
  }
  .radio-group {
    .MuiRadio-root {
      float: left;
    }
    .radio-label {
      margin-top: 10px;
    }
  }
}
.user-details-self {
  display: inline-block;
  margin: 9px 8px 0;
}
.custom-alert.mem-alert {
  padding-left: 12px !important;
}
.list-with-radio {
  list-style: inside none;
  li {
    display: flex;
    flex-direction: row;
    .radio-notes {
      padding: 5px 0 12px 10px;
    }
  }
  .sub-radio {
    min-width: 35px;
    position: relative;
    top: -4px;
  }
}
.card-member-details {
  max-width: 650px;
  margin: 0 auto;
}
/*--Custom Tabber Starts Here--*/
.custom-tabber {
  & .MuiTab-wrapper {
    font-weight: 600;
    line-height: 1;
    font-size: 1.25em;
    letter-spacing: normal;
  }
  .Mui-selected {
    color:$PrimaryColor !important;
    font-weight: 700 !important;
  }
  .MuiTab-root {
    text-transform: capitalize !important;
    min-width: 120px !important;
    &:focus {
    outline: none;
    border: none
    }
  }
  .MuiAppBar-colorDefault {
    background: none;
    box-shadow: none;
    font-weight: 600;
    padding: 0 15px;
    .MuiTabs-root {
      border-bottom: solid 1px #aaafb9;
    }
  }
  .MuiTabs-indicator {
    background-color:$PrimaryColor !important;
  }
  .MuiBox-root {
    padding: 0 !important;
  }
}
.fit-tab-1 {
  .MuiTab-root {
    max-width: none;
  }
}
/*--Custom Tabber ends Here--*/
/*--Custom classes starts Here--*/
.m-0, .form-wrapper-iox .no-label .MuiInput-root {
  margin: 0 !important;
}
/*-- == --*/
.browser-head {
	background-color: $PrimaryColor ;
	color: white
}
.border-head{
	border-right: 1px solid #AAAFB9;
}
.iox-modal-box .makeStyles-paper-9{
  top: 50% !important;
  left: 50% !important;
  -webkit-transform: translate(-50%, -50%)!important;
  transform: translate(-50%, -50%)!important;
}
.react-pdf__Document {
  .react-pdf__Page {
      border-bottom: solid 2px #999;
      canvas {
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
      }
  }
  .react-pdf__Page:last-child {
    border: none;;
  }
}
//for custom datepicker

.datepicker table tr td span {
  width: 100% !important;
}

.Qdatepicker {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
  color: rgba(0, 0, 0, 0.87);
  cursor: text;
  display: inline-flex;
  position: relative;
  font-size: 1rem;
  box-sizing: border-box;
  align-items: center;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.1876em;
  letter-spacing: 0.00938em;
}
.Qdateinput{
  height: 40px !important;
  border: 0.8px solid #707070 !important;
  border-radius: 5px !important;
  padding: 2px 12px !important;
  cursor: "pointer"
}
.Qdateinput:disabled{
  background: #f4f4f4 !important;
  border-color: #eee !important;
}
.Qdateinput:read-only{
  background:initial;
}
 .datepicker-years,.datepicker-decades,.datepicker-centuries {
  max-height: 254px;
  overflow: scroll;
  overflow-x: hidden;
} 
/////

//auto suggest input
.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: 100%;
  height: 40px;
    border: 0.8px solid #707070;
    border-radius: 5px;
    padding: 2px 12px;
}
.react-autosuggest__input_error{
  border: 1px solid #a81f00 !important;
  width: 100%;
  height: 40px;
  border-radius: 5px;
  padding: 2px 12px;
}

.react-autosuggest__input--focused {
  outline: none;
}
.autosuggest__input_error--focused {
  outline: none;
}

.autosuggest__input_error:focus,.react-autosuggest__input--focus{
  outline: none !important;
}
.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  outline: none;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 40px;
  width: 100%;
  max-height: 200px;
  overflow: scroll;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

.autoSuggestionLabel{
  /* font-size: 1.22rem !important; */
  color: #274463 !important;
  /* font-size: 1.11em !important; */
  white-space: nowrap;
  font-weight: 700;
  margin-bottom: 0.13rem;
}
///
.table-center-text table tr{
  td:not(:first-child),th:not(:first-child){
    text-align: center;
  }
}

@media print {
  .pdfPrint {
     border: 1px solid white;
     height: 99%;
     page-break-after: avoid;
     page-break-before: avoid;
     align-content: center;

     .header, .header-space,
     .footer, .footer-space {
       height: 100px;
     }
     .header {
       position: fixed;
       top: 0;
     }
  }
 
}
.m-0.mb-3 {
    margin-bottom: 1.25rem !important;
}

.cursor_pointer{
  cursor: pointer;
}

.pdf_link a {
  text-indent: -999px;
  overflow: hidden;
}

@media print {
  body{
    -webkit-print-color-adjust: exact; /*chrome & webkit browsers*/
    color-adjust: exact; /*firefox & IE */
  } 
}

html {
  scroll-behavior: smooth;
}
.pg-center{
  display: flex !important;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    min-height: calc(100vh - 250px);
    height: 100%;
}

.closeConsent{
   display: flex;
   margin-top: 20px;;
  button{
    border: none;
    min-width: 100px;
    height: auto;
    border-radius: 3px;
    color: grey;
    margin-left: 12px;
    height: fit-content;
  }
}

.MuiTableCell-root{
  font-family:'Open Sans', sans-serif, Arial !important;
}
.login-tab {
  .carousel-indicators, .carousel-control-prev, .carousel-control-next {
  visibility: hidden;
  display: none;
  }
}